import React, {useEffect, useState} from 'react'
import { motion } from "framer-motion";
import client from '../client';
import { ReactComponent as LockIcon } from "../images/lock.svg";
import { ReactComponent as ArrowIcon } from "../images/icon-arrow.svg";
import styled from 'styled-components';
import BodyText from '../components/BodyText'


const MotionDiv = motion.div;

const Login = styled.div`
  background: #000;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  `;

const Form = styled.form`
  position: absolute;
  max-width: 333px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 23px;
  border-radius:10px;
`;
const Button = styled.button`
  //border-radius: 24px;
  background: #DC1928;
  margin: 16px 0 22px;
  text-transform: capitalize;
  //padding: 10px 20px;
`;
const Input = styled.input`
  //border: 1px solid rgba(255, 255, 255, 0.50);
  //background: #F2F2F2;
  width: 100%;
  //padding: 8px;
`;
const Heading = styled.h4`
  margin: 8px 0;
  //font-family: "gotham-book";
`;
const Link = styled.a`
  transition: padding-left .2s linear;
  &:hover {
    padding-left: 3px;
  }
`;
function Protected({ setIsVerified }) {
  const [ postData, setPostData ] = useState(null);

  useEffect(() => {
    client
      .fetch(`*[name == "Login"]{ blocks[_type == "login_form"][0] }`)
      .then((data) => {
        setPostData(data);
      })
      .catch(console.error);
  }, [])


  // Define the animation variants for fading
  const loginVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const checkPassword = (event) => {
    event.preventDefault(); // Prevent form from traditional submission
    const isPassword = document.getElementById("password").value;

    if (isPassword === "akqaxauspost2024") {
      localStorage.setItem("isVerified", "true"); // Store verification in localStorage
      setIsVerified(true);
    } else {
      alert("Sorry, please try again.");
    }
  };

  console.log('postData', postData)

  return (
    <MotionDiv
      variants={loginVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration:1.2 }}
    >
      {postData && postData.map((item, index) => {
        const { title, desc, input_label, input_placeholder, button_text, access_text, contact_link } = item.blocks;

        return (
          <Login as={MotionDiv} key={index}>
            <img src="./images/login-bg.svg" alt="Honda" style={{ position: 'absolute', top: '0', right: '0', width: '100%', height: '100%', objectFit: 'cover' }} />
            <Form onSubmit={checkPassword}>
              <LockIcon />

              <Heading>{title}</Heading>

              {desc && (
                <BodyText content={desc} />
              )}


              <label htmlFor="password" className="form-label">{input_label}</label>
              <Input id="password" name="password" type="password" placeholder={input_placeholder} className="form-control" />
              <Button type="submit" className="btn btn-primary">{button_text}</Button>
              <p className="mb-1"><strong>{access_text}</strong></p>
              <p className="small mb-2">
                <Link href={contact_link.url} className="text-decoration-none text-dark d-flex gap-2 align-items-baseline" >
                  <span className="p-1"><ArrowIcon /></span>
                  {contact_link.text}
                </Link>
              </p>
            </Form>
          </Login>
        )
      })}




    </MotionDiv>
  );
}

export default Protected;
