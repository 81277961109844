import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Col, Row, Card } from "react-bootstrap";
import { motion, useInView } from "framer-motion";
import client, { config } from '../client'
import imageUrlBuilder from '@sanity/image-url'
import { getFileAsset } from '@sanity/asset-utils'
import BodyText from './BodyText';
import { VideoModal } from "./modal/modalVideo";
import { ReactComponent as PlayIcon } from "../images/play.svg";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { ReactComponent as DownloadIcon1 } from "../images/download1.svg";
import { ReactComponent as IconPhone } from "../images/phone.svg";
import { ReactComponent as IconMail } from "../images/mail_outline.svg";
const { projectId, dataset } = config;

const builder = imageUrlBuilder(client)
const getSanityFileUrl = (fileAsset) => {

  if (fileAsset !== null) {
    const file = getFileAsset(fileAsset.asset, {
      projectId,
      dataset
    });

    return `${file.url}`;
  } else {
    return
  }
}

const urlFor = (source) => {
  return builder.image(source)
}

const ContentStyle = styled.div`
  margin-top: 0;
  background-image: ${(props) => `url(${props.backgroundImage})` || "none"};
  background-size: cover;
  text-align: ${(props) => props.align || "left"};
`;

const MotionDiv = styled(motion.div)`
  margin-top: 0;
  background-color: ${(props) => props.bg };
  border-radius: 0.5rem;
`;

const ContentBlock = ({
  bg,
  align = 'left',
  width,
  _type,
  label,
  title,
  video,
  desc,
  img,
  cta,
  download,
  subText,
  cards,
  footerText,
  button,
  buttons,
  id,
  anchor,
  link,
  fullVideo
}) => {
  const [isDownloadVisible, setIsDownloadVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const INIT_MARGIN_TOP = 200;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [isActive, setIsActive] = useState(false);
  const [isNZ, setIsNZ] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const trans = {
    duration: 0.6,
    ease: "easeOut",
  };

  const handleDownloadClick = () => {
    setIsDownloadVisible(true);
  };

  const handleVideoClose = () => {
    setModalShow(false);
  };

  const handleVideoOpen = (e, video) => {
    // if (heading === "The Manifesto Film") {
    //   setIsNZ(false);
    // } else {
    setIsNZ(video);
    // }
    setModalShow(true);
  };

  let ANITMED_STATE = 0;

  if (_type === "footer" || _type === "content-acknowledgement" ? 0 : 20) {
    // ANITMED_STATE = 30;
  }

  return (
    <MotionDiv
      ref={ref}
      transition={trans}
      animate={
        _type !== "content-acknowledgement" && ({
          marginTop: isInView ? 0 : INIT_MARGIN_TOP, duration: 5
        })
      }
      // onMouseEnter={() => setIsActive(true)}
      // onMouseLeave={() => setIsActive(false)}
      className="d-flex w-100 justify-content-center"
      bg={bg}
      id={anchor && anchor}
    >
      <VideoModal show={modalShow} nz_au={isNZ} onHide={handleVideoClose} />
        {/*<a href={link}>*/}
        <ContentStyle
          //backgroundImage={_type === "image" || _type === "video" ? img : null}
          type={_type}
          width={width}
          // align={align}
          className={`
            d-flex
            mx-auto
            w-100
            justify-content-center
            text-${align}
            ${_type} section ${_type === "image" ? "image-big" : ""}
            ${_type === "hero" ? "px-xl-6" :  ""}
          `}
        >
          <Row
            className={`
              justify-content-between
              ${id === "2" ? "flex-change" : id === "1" ? "flex-1" : ""}
              ${_type === "image-autoplay" && "w-100"}
            `}
          >
            <Col
              className={`
                d-md-flex flex-column
                ${_type === "image-autoplay" ? "px-0" : ''}
                ${_type === "video" ? "vert-index" : _type === "content-contents" ? "mb-0" : ""}
                ${_type === "hero" ? "border-start-xl-4 border-primary ms-xl-5 p-5 p-lg-3 ps-lg-6" :  ""}
              `}
              lg={_type === "hero" || _type === " footer"|| _type === "image-autoplay" ? 12 : undefined}
              xl={
                _type === "hero" || _type === "footer"
                  ? 6
                  : _type === "cards" || _type === "content-pitch"
                  ? 4
                  : undefined
              }
            >
            {label
              && _type !== "image"
              && _type !== "image-cap"
              && _type !== "image-left"
              && _type !== "image-right"
              && _type !== "video" && (
              <span className="label">
                {label}
              </span>
            )}
            {_type !== "video" &&
              (_type === "hero" ? (
                <>
                  <h2 className="mb-3">{title}</h2>
                  {desc && (
                    <BodyText content={desc} />
                  )}
                </>
              ) : _type === "video-autoplay" ? (
                <>
                  <video
                    className="video5"
                    src={video}
                    loop
                    muted
                    preload="auto"
                    width="100%"
                    height="100%"
                    playsinline
                    autoPlay={"autoplay"}
                  />
                  {desc && (
                    <BodyText content={desc} />
                  )}
                </>
              ) : _type === "image-autoplay" ? (
                  <>
                    <img className="image-autoplay object-fit-cover" src={urlFor(img).url()} alt={img.caption} />
                    {desc && (
                      <BodyText content={desc} />
                    )}
                  </>
                ) : _type === 'image' ? (
                  <>
                  <a download href={download ? `${getSanityFileUrl(download)}`  : ''} onClick={handleDownloadClick}>
                    <span className="label">
                      {label}
                    </span>
                    <h2 className="image-heading">
                      {title}
                    </h2>
                  </a>
                  {desc && (
                    <BodyText content={desc} />
                  )}
                </>
              ) : _type === "image-cap" ? (
                <div className="position-relative d-flex flex-column">
                  <img className="img-fluid" src={urlFor(img).url()} alt={img.caption} />

                  <div className="p-6 d-flex flex-column flex-grow-1">
                    <h4 className="text-body fw-medium mb-4">{title}</h4>
                    {desc && (
                      <BodyText className="text-body mb-5" content={desc} />
                    )}
                    {cta && (
                      <a download href={download ? `${getSanityFileUrl(download)}`  : ''} onClick={handleDownloadClick} className="mt-auto inner-icon-slide-in-down small stretched-link">
                        <DownloadIcon1 className="play-btn me-1 text-primary" />
                        <span className="small fw-semibold text-body">{cta}</span>
                      </a>
                    )}
                  </div>
                </div>
              ) : _type === "image-right" || _type === "image-left" ? (
                <div className="p-5 p-xl-8 row">
                  <div className={`d-flex flex-column justify-content-center align-items-start p-7 mx-auto order-1 col-12 col-md-4 col-lg-5 col-xl-4 ${_type === "image-left" ? "order-lg-1 pe-xl-5" : "ps-xl-5"}`}>
                    <span className="caption text-primary mb-1">
                      {label}
                    </span>
                    <h3 className="text-body fw-medium mb-4">{title}</h3>
                    {desc && (
                      <BodyText className="text-body mb-4" content={desc} />
                    )}
                    {cta && (
                      <a
                        download
                        className="mt-0 btn btn-primary inner-icon-slide-in-down"
                        href={download ? `${getSanityFileUrl(download)}`  : ''}
                        onClick={handleDownloadClick}
                      >
                        <DownloadIcon1 className="play-btn me-1" />
                        {cta}
                      </a>
                    )}
                  </div>

                  <div className={`position-relative order-0 col-12 col-md-8 col-lg-7 col-xl-8 ${_type === "image-right" ? "order-lg-1" : ""}`}>
                    <img className="object-fit-cover w-100 h-100" src={urlFor(img).url()} alt={img.caption} />
                    <a download href={download ? `${getSanityFileUrl(download)}`  : ''} onClick={handleDownloadClick} className="stretched-link inner-icon-slide-in-down">
                      <DownloadIcon style={{"bottom": "23px", "right": "23px"}} className="position-absolute text-primary" />
                    </a>
                  </div>
                </div>

                ) : _type === "content-acknowledgement" ? (
                  <div className="d-md-flex gap-5 align-items-center text-white">
                    <img src={urlFor(img).url()} alt={title} className="mb-2 mb-md-0" width={'130px'} height={'100%'} />
                    {desc && (
                      <BodyText content={desc} />
                    )}
                  </div>
                ) : (
                  <>
                    {title && (
                      <h2 className="mt-1 mb-4 fw-medium">{title}</h2>
                    )}
                    {desc && (
                      <BodyText content={desc} />
                    )}
                  </>
                )
            )}

            {_type === "content" && download &&
              <a class="mt-0 btn btn-primary outline inner-icon-slide-in-down" href={download ? `${getSanityFileUrl(download)}`  : ''}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="play-btn"><path d="M19.5 9.5H15.5V3.5H9.5V9.5H5.5L12.5 16.5L19.5 9.5ZM5.5 18.5V20.5H19.5V18.5H5.5Z" fill="white"></path></svg>Download Video
              </a>
            }
            {_type === "video" && (
              <div>
                <video
                  className="video6"
                  src={video}
                  loop
                  muted
                  preload="auto"
                  width="100%"
                  height="100%"
                  playsinline
                  autoPlay={"autoplay"}
                  onClick={(e) => {
                    handleVideoOpen(e, fullVideo);
                  }}
                />
                <div className="video-spacing" href={download ? `${getSanityFileUrl(download)}`  : ''} onClick={handleDownloadClick}>
                  <span className="label">
                    {label}
                  </span>
                  <h2>{title}</h2>
                </div>
                <PlayIcon
                  className="play-btn"
                  onClick={(e) => {
                    handleVideoOpen(e, fullVideo);
                  }}
                />,
              </div>
            )}
            {_type === "image" && (
              <a download href={download ? `${getSanityFileUrl(download)}`  : ''} onClick={handleDownloadClick}>
                <DownloadIcon className="play-btn" />
              </a>
            )}
          </Col>
          {_type === "hero" && (
            <>
              <div className="d-none d-lg-block w-100"></div>
              <Col
                className=" d-xl-flex flex-column align-items-end justify-content-end ms-auto px-lg-5"
                lg={_type === "hero" || _type === "footer" ? undefined : undefined}
                xl={_type === "hero" ? 5 : _type === "cards" ? 4 : undefined}
              >
                <div style={{"width": "fit-content"}} className="bg-dark text-white rounded-3 w-100 mx-xl-2 p-5">
                  {subText && (
                    <MotionDiv animate={{ marginLeft: isActive ? 0 : 0, duration: 1.4 }}>
                      <BodyText content={subText} />
                    </MotionDiv>
                  )}

                  {cta && (
                    // target={"self"} rel="noopener"
                    <a
                      download
                      className={`mt-0 btn btn-primary inner-icon-slide-in-down  ${
                        _type === "hero" || _type === "footer" ? "outline" : ""
                      }`}
                      href={getSanityFileUrl(download)}
                      onClick={handleDownloadClick}
                    >
                      <DownloadIcon1 className="play-btn me-1" />
                      {cta}
                    </a>
                  )}
                </div>
              </Col>
            </>
          )}
          {_type === "footer" && (
            <Col
              className="mt-4 font-weight-bold"
              lg={_type === "hero" || _type === "footer" ? undefined : undefined}
              xl={_type === "footer" ? 12 : undefined}
            >

              {footerText && (
                <MotionDiv animate={{marginLeft: isActive ? 0 : 0, duration: 1.4}}>
                  <BodyText content={footerText} />
                  {/*<a target="_blank" href={button.url}*/}
                  {/*   className={`btn rounded-pill ${button.variant}`}>{button.text}</a>*/}

                  {buttons && (
                    <div className="d-flex gap-3">
                      {buttons.map((button, index) => {
                        const iconComponents = {
                          phone: IconPhone,
                          mail: IconMail,
                        };
                        const IconComponent = button.icon && iconComponents[button.icon];

                        return (
                          <a key={index} href={button.url} className={`btn rounded-pill ${button.variant} ${IconComponent && 'px-3'}`}>
                            {IconComponent ? (
                              <IconComponent />
                            ) : (
                              button.text
                            )}
                          </a>
                        )
                      })}
                    </div>
                  )}
                </MotionDiv>
              )}
            </Col>
          )}

            {(_type === 'content' && img && (
              <Col lg={12} xl={8}>
                <a
                  className="d-block position-relative play-btn "
                  href={download ? `${getSanityFileUrl(download)}`  : ''}
                  onClick={handleDownloadClick}
                  download
                ><img src={urlFor(img).url()} alt={title} width={"100%"} height={"100%"} />
                <DownloadIcon style={{"bottom": "32px", "right": "32px"}} className="position-absolute" />
                </a>
              </Col>
            ))}
          {_type === "footer" && img && (
            <img width="100%" height="auto" src={urlFor(img).url()} alt={title} />
          )}

          {cards && cards.length > 0 && (
            <Col lg={12} xl={8}>
              <Row>
                {cards &&
                  cards.map((card, index) =>
                    card.download ? (
                      <Col key={index}>
                        <a href={card.download}>
                          <Card>
                            <Card.Img variant="top" src={card.img} />
                            <Card.Body>
                              <Card.Text>{card.heading}</Card.Text>
                            </Card.Body>
                          </Card>
                        </a>
                      </Col>
                    ) : (
                      <Col key={index}>
                        <Card>
                          <Card.Img variant="top" src={card.img} />
                          <Card.Body>
                            <Card.Text>{card.heading}</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ),
                  )}
              </Row>
            </Col>
          )}
        </Row>
      </ContentStyle>
      {/*</a>*/}
    </MotionDiv>
  );
};

export default ContentBlock;
