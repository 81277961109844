import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import Home from "./pages/home";
import Protected from "./pages/protected";


const Container = styled.div`
  //text-align: center;
  position: relative;
  height: 100vh;
  background: #000;
`;


const App = () => {
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);

  // Check for verification flag in localStorage on component mount
  useState(() => {
    const verified = localStorage.getItem("isVerified");
    if (verified) {
      setIsVerified(true);
    }
  }, []);

  return (
    <Container>
      <AnimatePresence>
        {!isVerified && (
          <Protected setIsVerified={setIsVerified} />
        )}
      </AnimatePresence>
      {isVerified && (
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<Home />} />
        </Routes>
      )}
    </Container>
  );
};

export default App;
