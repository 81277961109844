import React, { useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components";

const VideoContainer = styled.div`
  padding:0;
  position:absolute;
`;

const IFrame = styled.iframe`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;

export function VideoModal(props) {

  const handleClose = () => {
    props.onHide();
  };

  const handleVideoEnded = () => {
    handleClose();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen
      onHide={handleClose}
      className="video-modal"
      data-bs-theme="dark"
    >
      <Modal.Header closeButton btn-close-white>
      </Modal.Header>
      <Modal.Body>
        <div class="ratio ratio-16x9">
          <VideoContainer>
            {
              !props.nz_au ?
              <IFrame src={props.nz_au} width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></IFrame>
              :
              <IFrame src={props.nz_au} width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></IFrame>
            }
          </VideoContainer>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </Modal.Body>
    </Modal>
  );
}
