import sanityClient from '@sanity/client'

const config = {
  projectId: 'bt7ffhek',
  dataset: 'production'
};

const client = sanityClient(config);

export { config, client };
export default client;
