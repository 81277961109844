import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Col, Row } from "react-bootstrap";
import client from '../client';
import ContentBlock from "../components/contentBlock";
import { ReactComponent as Logos } from "../images/logo-lockup.svg";

function Home() {

  // const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });
  // const [isActive, setIsActive] = useState(false);
  const [ postData, setPostData ] = useState(null);


  useEffect(() => {
    client
      .fetch(`*[name == "Homepage"]{ blocks }`)
      .then((data) => {
        console.log('postData', data[0]['blocks']);
        setPostData(data[0]['blocks']);
      })
      .catch(console.error);
  }, [])

  return (
    <motion.div
      transition={{
        delay: 1,
        opacity: { duration: 1 },
        default: { ease: "easeInOut" },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        paddingBottom: '1rem',
        backgroundColor: '#f5f5f5'
      }}
    >
      <div className='sections-container container-lg'>
        <div className='header row align-items-end px-xl-6'>
          <Col xs="12" md className='text-start'>
            <Logos />
            {/*<img width="100%" height="auto" src={img} alt={"AKQA Pitch"} />*/}
          </Col>
          <Col xs="12" md className='text-lg-end'>
            <p className="mb-1"><strong>Last Update:</strong> 15th May 2024</p>
          </Col>
        </div>

          <Row className="g-5 mt-lg-0" >
            {postData && postData.map((item, index) => {
              return (
              <Col xs={12} sm={parseInt(item.width)} key={index} className="d-flex">
                <ContentBlock {...item} />
              </Col>
            )})}

            <Col xs="12" sm="3" className="d-flex">
              <div className="d-flex mx-auto w-100 justify-content-center align-items-center text-left content-acknowledgement section rounded-3" style={{backgroundColor: '#31313D'}}>

                <div className='d-flex justify-content-center gap-3'>
                    <a target='_blank' href='https://www.instagram.com/akqa/' rel="noreferrer">
                      <img src='images/socials_insta.svg' alt="AKQA Instagram" />
                    </a>
                  <a target='_blank' href='https://www.facebook.com/akqa/' rel="noreferrer">
                    <img src='images/socials_fb.svg' alt="AKQA Facebook" />
                  </a>
                  <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/akqa/'>
                    <img src='images/socials_linkedin.svg' alt="AKQA LinkedIn" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
      </div>
    </motion.div>
  );
}

export default Home;
