import { PortableText } from '@portabletext/react'

const portableTextComponents = {
  block: {
    h1: ({children}) => <h1>{children}</h1>,
    h2: ({children}) => <h2>{children}</h2>,
    h3: ({children}) => <h3>{children}</h3>,
    h4: ({children}) => <h4>{children}</h4>,
    h5: ({children}) => <h5>{children}</h5>,
    lead: ({children}) => <p className="lead">{children}</p>,
    normal: ({children}) => <p>{children}</p>,
    mb2: ({children}) => <p className="mb-2">{children}</p>,
    mb0: ({children}) => <p className="mb-0">{children}</p>,
    small: ({children}) => <p className="small">{children}</p>,
    smallNoMargin: ({children}) => <p className="small mb-0">{children}</p>,

  },

  marks: {
    em: ({children}) => <em className="text-gray-600 font-semibold">{children}</em>,

  }
}

const BodyText = ({ content }) => {
  return <PortableText
    value={content}
    components={portableTextComponents}
  />
}

export default BodyText;
